import React,{useEffect,useRef,useState} from 'react'
import WebTemplate from '../Components/WebTemplate'
import { useGetAllSchoolsQuery} from '../Services/modules/school';
import { Link,useSearchParams  } from 'react-router-dom';
import { Config } from '../Config';

function ExamLanding() {
 

    const ResultLandingScreen=()=>{  
    
        return(
            <>
           <section class="heroschoolsResult">
  <div class="heroschoolsResult-content">
    <h2 className="text-overlay" style={{color:'#ffffff'}}>परीक्षा</h2>
   
  </div> 
</section>


              <main id="main">
              <section id="clients" class="clients section-bg">
      <div class="container">

        <div class="row" data-aos="zoom-in">
        {/* <div class="alert alert-danger" role="alert">
        इ. ६वी आणि ७वी च्या अंतरीम उत्तरसूची दि. ०४/०२/२०२४ रोजी अपलोड केल्या जातील.
</div> */}

        <table className="table">
      <thead>
        <tr>
        <th style={{textAlign:'center'}}>Index</th>
         
          <th style={{textAlign:'center'}}>गुणवत्ता शोध व शताब्दी शिष्यवृत्ती परीक्षा अंतिम उत्तरसूची 2024</th>
         
        </tr>
      </thead>
      <tbody>
      <tr>
            <td>1</td>
            <td>           
            <a href={`${Config.file_url}/uttarsuchi/antim/1li.pdf`} target="_blank" style={{ color: 'blue' }}>
            गुणवत्ता शोध परीक्षा अंतिम उत्तरसूची १ ली 
              </a>
              </td>           
          </tr>
          <tr>
            <td>2</td>
            <td>           
            <a href={`${Config.file_url}/uttarsuchi/antim/2ri.pdf`} target="_blank" style={{ color: 'blue' }}>
            गुणवत्ता शोध परीक्षा अंतिम उत्तरसूची २ री
              </a>
              </td>           
          </tr>
         
          <tr>
            <td>3</td>
            <td>           
            <a href={`${Config.file_url}/uttarsuchi/antim/3ri.pdf`} target="_blank" style={{ color: 'blue' }}>
            गुणवत्ता शोध परीक्षा अंतिम उत्तरसूची ३ री
              </a>
              </td>           
          </tr>
          <tr>
            <td>4</td>
            <td>           
            <a href={`${Config.file_url}/uttarsuchi/antim/6vi.pdf`} target="_blank" style={{ color: 'blue' }}>
            गुणवत्ता शोध परीक्षा अंतिम उत्तरसूची ६ वी
              </a>
              </td>           
          </tr>
          <tr>
            <td>5</td>
            <td>           
            <a href={`${Config.file_url}/uttarsuchi/antim/4thi.pdf`} target="_blank" style={{ color: 'blue' }}>
            शताब्दी शिष्यवृत्ती परीक्षा अंतिम उत्तरसूची ४ थी
              </a>
              </td>           
          </tr>
          <tr>
            <td>6</td>
            <td>           
            <a href={`${Config.file_url}/uttarsuchi/antim/7vi.pdf`} target="_blank" style={{ color: 'blue' }}>
            शताब्दी शिष्यवृत्ती परीक्षा अंतिम उत्तरसूची ७ वी
              </a>
              </td>           
          </tr>
      </tbody>
    </table>
        

        </div>

      </div>
    </section>
    <section id="services" class="services">
      <div class="container" data-aos="fade-up">

       
        <div class="row">
         
        </div>

      </div>
    </section>



</main>
            </>
        )
    }
  return (
  <WebTemplate>
    {ResultLandingScreen()}
  </WebTemplate>
  )
}

export default ExamLanding
export const Config = {
   
      // API_URL_LOCAL: 'http://localhost:8000/api/',
      // file_url: 'http://localhost:8000',
      file_url: 'https://backend.sanglishikshansanstha.com',

  API_URL_LOCAL: 'https://backend.sanglishikshansanstha.com/api/',
   // file_url: 'https://backend.sanglishikshansanstha.com',
  
    // file_url: 'https://result.sanglishikshansanstha.com',

    
  // API_URL_LOCAL: 'https://backend.sanglishikshansanstha.com/api/'
    
    }
    
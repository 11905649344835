import { api } from '../../api'
import getPersonalResult from '../school/getPersonalResult'
import checkCenterMeritListShatabdi from './checkCenterMeritListShatabdi'
import checkCenterMeritListTse from './checkCenterMeritListTse'
import checkSchoolWiseResult from './checkSchoolWiseResult'
import getAllCenters from './getAllCenters'
import getResult from './getResult'


export const resultsApi = api.injectEndpoints({
    endpoints: build => ({
        GetAllCenters: getAllCenters(build),         
        GetResult:getResult(build), 
        CheckCenterMeritListTse:checkCenterMeritListTse(build),
        CheckCenterMeritListShatabdi:checkCenterMeritListShatabdi(build),    
        CheckSchoolwiseResult:checkSchoolWiseResult(build)
    }),
    overrideExisting: true,   
})
export const {
    useGetAllCentersQuery,
    useGetResultMutation,
    useCheckCenterMeritListTseMutation,
    useCheckCenterMeritListShatabdiMutation,
    useCheckSchoolwiseResultMutation
} = resultsApi

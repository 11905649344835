import React,{useEffect} from 'react'
//import { useSelector } from 'react-redux'
import { 
 
    Route,
    Routes,     
    Navigate,
    Outlet,
  
   } from 'react-router-dom'

import Home from './Pages/Home'
import Podcasts from './Pages/Podcasts';
import Schools from './Pages/Schools';
import SchoolProfile from './Pages/SchoolProfile';
import ResultLanding from './Pages/ResultLanding';
import TseResult from './Pages/TseResult';
import ShatabdiResult from './Pages/ShatabdiResult';
import TermsAndConditions from './Pages/TermsAndConditions';
import PrivacyPolicy from './Pages/PrivacyPolicy';
import RefundPolicy from './Pages/RefundPolicy';
import ExamLanding from './Pages/ExamLanding';

function AppRoutes() {
  
    const ProtectedRoute = ({
        isAllowed,
        redirectPath = '/',
        children,
      }) => {
        if (!isAllowed) {
          return <Navigate to={redirectPath} replace />;
        }
      
        return children ? children : <Outlet />;
      };
  return (
    <>
 
   <Routes>
        <Route index element={<Home/>}/>
        <Route path="/schools" element={<Schools/>}/>
        <Route path="/schoolprofile" element={<SchoolProfile/>}/>
        <Route path="/resultlanding" element={<ResultLanding/>}/>
        <Route path="/examlanding" element={<ExamLanding/>}/>

        <Route path="/tseresult" element={<TseResult/>}/>
        <Route path="/shatabdiresult" element={<ShatabdiResult/>}/>
        <Route path="/terms-conditions" element={<TermsAndConditions/>}/>
        <Route path="/privacy-policy" element={<PrivacyPolicy/>}/>
        <Route path="/refund-policy" element={<RefundPolicy/>}/>

        {/* <Route element={<ProtectedRoute isAllowed={isLoggedin} />}>
        <Route path="dashboard" element={<Dashboard/>}/>
        <Route path="/course" element={<Course/>}/>
        <Route path="/coursecontent" element={<CourseContent/>}/>
        <Route path="/coursepackage" element={<CoursePackage/>}/>

       
        </Route> */}
    </Routes>
  
  
    </>
  )
}

export default AppRoutes
export default build =>
    build.query({
        tagTypes: ['allCenters'],
        keepUnusedDataFor: 1,
        query() {
            
            //console.log("payload",payload)            
            return {
                url: '/result/getAllCenters',
                method: 'GET',
                //body: payload,
            };
           
        },
       providesTags: ['allCenters']
    })

import React from 'react'
import HeaderNavbar from './HeaderNavbar'
import Footer from './Footer'

function WebTemplate({children}) {
  return (
    <>
<HeaderNavbar/>
{children}
<Footer/>
    </>
  )
}

export default WebTemplate
import React,{useEffect,useRef,useState} from 'react'
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { useCheckCenterMeritListShatabdiMutation, useCheckCenterMeritListTseMutation, useGetAllCentersQuery, useGetResultMutation } from '../Services/modules/result';
import Spinner from 'react-bootstrap/Spinner';
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

import 'react-toastify/dist/ReactToastify.css';
import checkCenterMeritListShatabdi from '../Services/modules/result/checkCenterMeritListShatabdi';
import { Config } from '../Config';

function CenterMeritList({exam_type}) {
  const navigate = useNavigate();
    const [center, setCenter] = useState('');
    const [standard, setStandard] = useState('');
    const [isSubmit, setIsSubmit] = useState(false)

    const [filteredData, setFilteredData] = useState([]);
  const [isFormValid, setIsFormValid] = useState(false);
  
  const[resultDisplay,setResultDisplay]=useState([])
  const[filePath,setFilePath]=useState('')
    const[fileNameState,setFileNameState]=useState('')
  const { 
    data: centers= [],  
    isLoading:loading=true,
    isSuccess:getCentersSuccess=false
   } = useGetAllCentersQuery();
   const [CheckCenterMeritListTse, 
    { data, isSuccess, isLoading, isError, error }]
     = useCheckCenterMeritListTseMutation();
     const [CheckCenterMeritListShatabdi, 
        { data:shatbdiMeritListData, 
            isSuccess:isShatabdiMeritListSuccess,
             isLoading:isShatabdiMeriListLoading,
              isError:isShatabdiMeritListError
             }]
         = useCheckCenterMeritListShatabdiMutation();
     useEffect(() => {
       
        validateForm();
      }, [center,standard]);
       useEffect(() => {    
         
        if (getCentersSuccess) {
           
           console.log("centers.data",centers.data)
              setFilteredData(centers.data);
            
        }
       
    }, [centers]);
    useEffect(() => {   
         
        if (isSuccess) {         
           
          if(data.error) 
          {
            toast.error(data.message)    
            setFilePath('') 
          }
          if(!data.error)
          {
            toast.success(data.message) 
           
    // setFilePath(`http://localhost:8000/CenterMeritList/tse/${fileNameState}`)
    setFilePath(`
    ${Config.file_url}/CenterMeritList/tse/${fileNameState}`)


              
          }
                    
        }
        else {
        console.log("In error")
           
        }
      }, [isLoading]);
      useEffect(() => {   
         
        if (isShatabdiMeritListSuccess) {         
           console.log("shatabdiData",data)
          if(shatbdiMeritListData.error) 
          {
            toast.error(shatbdiMeritListData.message)      
            setFilePath('') 
          }
          if(!shatbdiMeritListData.error)
          {
          
     setFilePath(`
     ${Config.file_url}/CenterMeritList/shatabdi/${fileNameState}`)

            toast.success(shatbdiMeritListData.message)                
          }                    
        }
        else {
        console.log("In error")           
        }
      }, [isShatabdiMeriListLoading]);
   
    const validateForm = () => {
         
        
      if (center !== '' && standard!=='') {
        setIsFormValid(true);
      } 
      else 
      {           
        setIsFormValid(false);
      }       
    }; 
    const handleFormSubmit = event => {
       let file_name=standard+'_'+center+'.pdf'
       setFileNameState(file_name)
     //  alert(file_name)
     if(exam_type=='tse')
     {
        CheckCenterMeritListTse({file_name:file_name})
     }
     if(exam_type=='shatabdi')
     {
        CheckCenterMeritListShatabdi({file_name:file_name})
     }
    // setIsSubmit(true)
    // setFilePath(`http://localhost:8000/CenterMeritList/tse/${file_name}`)
      // navigate(`/CenterMeritList/tse/${file_name}`);
    
        };
    
  return (
    <>
     <div className='row'>
            <div className='col-md-4'>
            <div className='row' style={{marginTop:'50px'}}>
       <h4 style={{textAlign:'center'}}>केंद्रनिहाय गुणवत्ता यादी खालील प्रमाणे पहा</h4>
        </div>
          
      <Form.Group controlId="exampleForm.ControlSelect1">
        <Form.Label style={{marginTop:'20px'}}>केंद्र निवडा:</Form.Label>
        <Form.Control as="select" value={center} onChange={e => setCenter(e.target.value)}>
          <option value="">Choose...</option>
          {filteredData.map(item => (
                        <option value={item.center_id}>{item.center_name}</option>
                      ))}
        </Form.Control>
      </Form.Group>
      <Form.Group controlId="exampleForm.ControlInput1">
        <Form.Label style={{marginTop:'20px'}}>इयत्ता:</Form.Label>
       {exam_type=='tse'? 
       <Form.Control as="select" value={standard} onChange={e => setStandard(e.target.value)}>
          <option value="">Choose...</option>        
      
         <option value={1}>1</option>
         <option value={2}>2</option>
         <option value={3}>3</option>
         <option value={6}>6</option>               
        </Form.Control>:
         <Form.Control as="select" value={standard} onChange={e => setStandard(e.target.value)}>
         <option value="">Choose...</option>        
     
        <option value={4}>4</option>
        <option value={7}>7</option>
                
       </Form.Control>
        }
      </Form.Group>
      <Button
  style={{ marginTop: '20px' }}
  variant="primary"
  disabled={!isFormValid || isLoading || isShatabdiMeriListLoading}
  type="submit"
  onClick={()=>handleFormSubmit()}
>
{isLoading || isShatabdiMeriListLoading ? (
    <Spinner animation="border" role="status">
      <span className="sr-only">Loading...</span>
    </Spinner>
  ) : (
    'Submit'
  )}
</Button>
     
   
            </div>
            <div className='col-md-6' >
          
          {filePath!==''? <table class="table"style={{marginTop:'40px',marginLeft:"40px"}} >
    <thead>

      <tr>
      {
      standard==1 || standard==2 || standard==3 || standard==6 ?
        <th colSpan={2} style={{textAlign:'center'}}>
          गुणवत्ता शोध परीक्षा - केंद्रनिहाय गुणवत्ता यादी खालील प्रमाणे</th>:
           <th colSpan={2} style={{textAlign:'center'}}>
           शताब्दी शिष्यवृत्ती परीक्षा - केंद्रनिहाय गुणवत्ता यादी खालील प्रमाणे</th>
    }
       
      </tr>
    </thead>
    <tbody>
   

      <tr>
       
        <td style={{textAlign:'left'}}>
        <a href={filePath} target="_blank" style={{color:'blue'}}>
        सदर केंद्राची गुणवत्ता यादी पाहण्यासाठी येथे क्लिक करा    </a>
        </td>
       
      </tr>
      
     
     
    </tbody>
  </table>:""}
            </div>
        </div>
    </>
  )
}

export default CenterMeritList
import { api } from '../../api'

import getAllSchools from './getAllSchools'
import getSchoolById from './getSchoolById'

export const getAllSchoolsApi = api.injectEndpoints({
    endpoints: build => ({
        GetAllSchools: getAllSchools(build),  
        GetSchoolById:getSchoolById(build)
    }),
    overrideExisting: true,
    //providesTags: ['Research']
})
export const {useGetSchoolByIdQuery, useGetAllSchoolsQuery} = getAllSchoolsApi

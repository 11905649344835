import React from 'react'

function RefundPolicy() {
  return (
    <div className='container'>
        <h2><strong>Refunds policy</strong></h2>
        <li>Once exam form filling is completed, it cannot be 
            cancelled under any circumstances. Additionally, 
            the fees are non-refundable and non-transferable,
             regardless of the reason, whether it's man-made or
              natural. 
</li>
    </div>
  )
}

export default RefundPolicy
export default build =>
    build.query({
        query(payload) {
           // console.log("payload",payload)
            return {
                url: '/school/getSchoolById',
                method: 'POST',
                body: payload,
               
            };
        },
        //invalidatesTags: ['allSchools'],
    })
